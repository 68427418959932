import React, { ReactElement, useMemo } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useBlockScene, useResultsInvalidation } from '@editorHooks'
import { useModelInvalidation, useStructuralPlanningFormEsc } from '@structuralPlanningHooks'
import { createOpening, deleteOpening } from '@mutations'
import { errors } from 'src/constants'
import { buildErrorMessage } from 'src/constants/errors'
import GeometryForm from './components/GeometryForm'

interface Props {
  selectedWall: string | null
  selectedOpening: string | null
  deselectWall: () => void
}

const OpeningForm = ({ selectedOpening, selectedWall, deselectWall }: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()

  const { projectId } = useParams()

  const invalidateResults = useResultsInvalidation()

  const { walls } = useModelStore(state => state.model)
  const removeOpening = useModelStore(state => state.removeOpening)

  const invalidateModel = useModelInvalidation()

  const setActiveOpening = useEditElementStore(state => state.setActiveOpening)

  // MEMOS

  const wall = useMemo(() => find(walls, { guid: selectedWall }), [walls, selectedWall]) as
    | ShapeObject
    | undefined

  const opening = useMemo(
    () => (selectedOpening ? find(wall?.openings, { guid: selectedOpening }) : undefined),
    [wall, selectedOpening],
  )

  const { mutateAsync, isLoading } = useMutation(
    (opening: Opening) =>
      createOpening.request(projectId, {
        element_guid: selectedWall,
        shape: {
          points: opening.shape.points,
        },
      }),
    {
      onSuccess: async () => {
        await invalidateModel(projectId as string)
        invalidateResults(projectId as string)
        enqueueSnackbar('Öffnung erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        if (errors[error.response?.data.code]) {
          enqueueSnackbar(errors[error.response?.data.code], { variant: 'error' })
          return
        }
        enqueueSnackbar('Fehler beim Speichern der Öffnung', { variant: 'error' })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    ({ wallGuid, openingGuid }: { wallGuid: string; openingGuid: string }) =>
      deleteOpening.request(projectId, wallGuid, openingGuid),
    {
      onSuccess: async () => {
        await invalidateModel(projectId as string)
        invalidateResults(projectId as string)
        enqueueSnackbar('Öffnung erfolgreich gelöscht', { variant: 'success' })
        setActiveOpening(null)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Öffnung'), {
          variant: 'error',
        })
      },
    },
  )

  useBlockScene(isLoading || isDeleting)

  useStructuralPlanningFormEsc(deselectWall, !!selectedWall)

  if (!selectedWall || !wall) {
    return (
      <>
        <Box component="div" p={1} border={1} borderColor="grey.200" borderRadius={1} mb={2}>
          <Typography mb={1}>
            Sie haben nun die Möglichkeit eine Wand anzuwählen um dort eine Öffnung einzuzeichnen
          </Typography>
        </Box>
      </>
    )
  }

  if (!selectedOpening) {
    return (
      <>
        <Box component="div" p={1} border={1} borderColor="grey.200" borderRadius={1} mb={2}>
          <Typography mb={1}>
            Wählen sie eine bestehende Öffnung oder zeichnen Sie eine neue ein
          </Typography>
        </Box>
      </>
    )
  }

  if (opening?.is_local) {
    return (
      <GeometryForm
        openingGuid={selectedOpening}
        wall={wall}
        handleSubmit={mutateAsync}
        isSubmitting={isLoading}
        isDeleting={isDeleting}
        handleDelete={() => removeOpening(selectedWall, selectedOpening)}
      />
    )
  }

  return (
    <>
      <GeometryForm
        openingGuid={selectedOpening}
        wall={wall}
        handleSubmit={() => alert('submit')}
        isSubmitting={isLoading}
        isDeleting={isDeleting}
        handleDelete={() => handleDelete({ wallGuid: selectedWall, openingGuid: selectedOpening })}
      />
    </>
  )
}

export default OpeningForm
